<template>
    <section class="supplier">
        <div class="top-bar bg-white" style="justify-content: left">
            <el-button size="small" type="primary" @click="openAdd">新建</el-button>
        </div>
        <div style="padding: 10px;background-color: #ffffff;border-radius: 10px">
            <r-e-table class="bg-white" ref="supplierTableRef" :dataRequest="getSupplierList" :columns="supplierTableColumn"
                       :query="formSearch" :height="750">
                <template slot="empty">
                    <el-empty/>
                </template>
                <!--<el-table-column slot="toolbar" label="操作" width="100">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" @click="row">编辑</span>
                            <span class="table-btn" @click="row">删除</span>
                        </div>
                    </template>
                </el-table-column>-->
            </r-e-table>
        </div>
        <dialog-add-supplier ref="dialogAddSupplier" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
    import {supplierTableColumn} from "@/views/property-management/assets-management/data";
    import {getSupplierList} from "@/api/supplier"
    export default {
        name: "supplier",
        data() {
            return {
                supplierTableColumn,
                formSearch: {}
            };
        },
        components: {
            dialogAddSupplier:()=>import("./components/dialog-add-supplier")
        },
        methods: {
            getSupplierList(params){
                let formSearch=this.formSearch;
                let paramsData={...params,...formSearch}
                return getSupplierList(paramsData);
            },
            openAdd(){
                this.$refs["dialogAddSupplier"].openDialog();
            },
            handleSearch(){
                this.$refs["supplierTableRef"].pNumber = 1;
                this.$refs["supplierTableRef"].getTableData();
            }
        },
        async mounted() {
        },
        watch: {
        }
    }
</script>

<style lang="scss" scoped>
    .supplier {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(10px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                //white-space: nowrap;
                text-align: center;
            }
        }

        .title {
            height: VH(50px);
            line-height: VH(50px);
            color: #666;
            padding-left: VW(10px);
            position: relative;

            &::before {
                width: 5px;
                height: 40%;
                background-color: #5C84FB;
                content: '';
                position: absolute;
                left: 0;
                top: 30%;
            }
        }
    }
</style>